import React from 'react';
import './Champsdapplication.css'; // Vous pouvez créer et utiliser ce fichier pour ajouter des styles spécifiques

const Champsdapplication: React.FC = () => {
  return (
    <div>
      <h2>Nos champs d’application</h2>
      <p>Il est important de comprendre que la thérapie psychocorporelle ne remplace pas un traitement médical ou psychologique, mais peut être utilisée comme un complément efficace pour améliorer le bien-être des personnes souffrant de pathologies mentales.</p>
      <br />
      <div className="consultation-card">
        <h2><strong>Pathologies mentales (Liste non exhaustive)</strong></h2>
        <ul>
          <li><strong>Troubles de l’humeur</strong> : dépression – symptômes dépressifs - trouble bipolaire…</li>
          <li><strong>Troubles anxieux</strong> : trouble d'anxiété généralisée (TAG) - phobies spécifiques et sociales – trouble obsessionnel-compulsif (TOC) …</li>
          <li><strong>Troubles de stress post-traumatique</strong> - stress aigu</li>
          <li><strong>Troubles de la personnalité</strong> : évitante – dépendante…</li>
          <li><strong>Troubles du comportement alimentaire</strong> : anorexie - boulimie…</li>
          <li><strong>Troubles de l’attachement</strong></li>
          <li><strong>Dépendances affectives – Addictions</strong></li>
        </ul>
      </div>

      <div className="consultation-card">
        <h2><strong>Les troubles du sommeil</strong></h2>
        <ul>
          <li><strong>Difficultés d’endormissement</strong></li>
          <li><strong>Réveils nocturnes</strong></li>
          <li><strong>Parasomnies</strong></li>
          <li><strong>S.J.S.R (syndrome des jambes sans repos)</strong></li>
        </ul>
      </div>

      
    </div>
  );
};

export default Champsdapplication;
