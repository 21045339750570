import React from 'react';
import photoPhilippe from '../../profilphilippe.jpg'; // Remplacez par le chemin réel de l'image
import photoFlorence from '../../profilflorence.jpeg'; // Remplacez par le chemin réel de l'image
import photoNathalie from '../../profilnathalie.png'; // Remplacez par le chemin réel de l'image
import './Notreequipe.css'; // Vous pouvez créer et utiliser ce fichier pour ajouter des styles spécifiques
import logorncp from '../../logorncp.jpg';


const Notreequipe: React.FC = () => {
  return (
    <div>
      <h1>Les thérapeutes</h1>
      <div className="team-section">
        <div className="team-member">
          <div className="team-member-card">
            <img src={photoPhilippe} alt="Photo de Philippe Dasmien" className="team-member-photo" />
            <h2>Philippe Dasmien</h2><br/>
            <p>Spécialisé en pathologies mentales et troubles du sommeil. Praticien en programmation neuro linguistique.</p>
          </div>
        </div>
        <div className="team-member">
          <div className="team-member-card">
            <img src={photoFlorence} alt="Photo de Florence Abougie" className="team-member-photo" />
            <h2>Florence Abougie</h2><br/>
            <p>Spécialisée dans les troubles du comportement alimentaire, troubles de l’humeur, dépendance affective, Hypnose.</p>
          </div>
        </div>
        
      </div>
      <br />
      <div className="team-perspective">
        <p className='h46'>Chaque thérapeute est diplômé(e) de l’École Supérieure de Sophrologie, certifié(e) <strong>RNCP</strong> et apporte sa propre perspective et son expérience, un processus thérapeutique qui offre une diversité de points de vue pour répondre aux besoins spécifiques de chacun. Nous consacrons notre temps à l’écoute et à l’accompagnement de personnes en souffrance en mettant nos accompagnements au centre de nos priorités.
          <br/>
          <br/>
        <a href="https://www.chambre-syndicale-sophrologie.fr/titre-rncp/" target="_blank">
  <img src={logorncp} alt="logo et illustration" className="image-container small" style={{width: '150px', height: 'auto' }} />
</a>


        </p>
      </div>
    </div>
  );
};

export default Notreequipe;
