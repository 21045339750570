import React from 'react';
import './Home.css';
import logotrsp from '../../logotrsp.png';
import illustration from '../../illustration.png';

const Home: React.FC = () => {
  return (
    <div className='home'>
      <img src={logotrsp} alt="Logo" className="image-container" />
      <br />
      <br />
      <div className="center-container">
        <img src={illustration} alt="logo et illustration" className="image-container small" />
        <br />

        <p className="intro consultation-card">
          Un espace dédié à l'accompagnement des adultes et adolescents, encadré par deux thérapeutes psychocorporels spécialisé(e)s et <strong>certifié(e)s RNCP</strong>, proposant une <strong>co-thérapie</strong> qui permet une approche globale et adaptée à vos besoins.
        </p>
      </div>
      <br />
      <div className="centered-text">
        <div className="theme-links-container">
          <a className="theme-link1" href="#champs-application">Troubles de l’humeur</a>
          <a className="theme-link4" href="#champs-application">Troubles anxieux</a>
          <a className="theme-link2" href="#champs-application">Troubles du sommeil</a>
        </div>
      </div>
      <h4 className='h4'>
        Nous vous accueillons du lundi au vendredi de 9h00 à 19h00 <br /> Le samedi de 9H00 a 12H00
      </h4>
      
      <button className="phone-button" onClick={() => window.location.href = 'tel:0489786685'}>
        Prendre rendez-vous
      </button>

      <br />
      <p>
        Une équipe engagée dans une dynamique de formation continue, pour vous assurer un suivi de qualité en constante évolution. En combinant nos connaissances et nos compétences, la co-thérapie permet une approche plus approfondie.
      </p>
      <br />
      <p className="centered">
        <strong>Nos thérapies ne se substituent pas à un traitement médicamenteux ni à un professionnel de santé.</strong>
      </p>
    </div>
  );
};

export default Home;
